<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Data Condition Monitoring'">
        <template v-slot:preview>

          <b-row>
            <b-col md="12" class="mb-2">
              <b-button variant="outline-dark" @click="$router.push('/report/conditional_report/add')">
                <span class="svg-icon svg-icon-sm svg-icon-dark">
                  <i class="flaticon2-plus"></i>
                </span>
                Create
              </b-button>
            </b-col>
            <b-col md="12">
              <b-table head-variant="dark" bordered :items="items" :fields="fields" :current-page="currentPage"
                stacked="xl" show-empty responsive hover :no-local-sorting="true" @sort-changed="sortTable"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" @filtered="onFiltered" :busy="isBusy"
                @row-clicked="info">
                <template #thead-top>
                  <b-tr>
                    <b-th colspan="1"></b-th>
                    <b-th>
                      <b-form-input id="filter-1" size="sm" @input="loadData" v-model="search.nama_equipment"
                        type="search" placeholder="Nama Equipment..."></b-form-input>
                    </b-th>

                    <b-th>
                      <b-form-input id="filter-2" size="sm" @input="loadData" v-model="search.nama_part" type="search"
                        placeholder="Nama Part..."></b-form-input>
                    </b-th>

                    <b-th>
                      <b-form-input id="filter-3" size="sm" @input="loadData" v-model="search.nama_sub_part" type="search"
                        placeholder="Nama Sub Part..."></b-form-input>
                    </b-th>

                    <b-th>
                      <b-form-select id="filter-4" @input="loadData" v-model="search.pabrik_id" :options="pabrik"
                        size="sm"></b-form-select>
                    </b-th>

                    <b-th>
                      <b-form-select id="filter-5" @input="loadData" v-model="search.plant_id" :options="plant"
                        size="sm"></b-form-select>
                    </b-th>

                    <b-th colspan="1"></b-th>
                  </b-tr>
                </template>

                <template #cell(no)="data">
                  {{ (currentPage - 1) * 10 + data.index + 1 }}
                </template>

                <template #cell(action)="data">
                  <b-dropdown text=". . ." variant="danger" size="sm" no-caret>
                    <b-dropdown-item-button @click="info(data.item)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-success">
                          <i class="flaticon-eye text-success"></i>
                        </span>
                      </div>
                      View
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="
                      $router.push(
                        '/report/conditional_report/edit/' + data.item.id_condition_monitoring
                      )
                      ">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-primary">
                          <i class="flaticon2-pen text-primary"></i>
                        </span>
                      </div>
                      Edit
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="toDelete(data)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                      </div>
                      Delete
                    </b-dropdown-item-button>
                  </b-dropdown>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col md="5">
              <b-pagination v-model="currentPage" @input="paginate" :total-rows="totalRows" :per-page="10" align="fill"
                size="sm" class="my-0"></b-pagination>
            </b-col>
            <b-col md="5">
              <h4 class="float-right">
                Showing
                <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                <span v-else>0</span>

                of {{ totalRows }}
              </h4>
            </b-col>
          </b-row>
          <b-modal id="info-modal" size="xl" title="Detail" ok-only ok-title="Tutup" @hide="resetInfoModal">
            <b-row>
              <b-col md="6">
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Equipment :</b></b-col>
                  <b-col>{{ modalInfo.nama_equipment }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Pabrik :</b></b-col>
                  <b-col>{{ modalInfo.nama_pabrik }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Plant :</b></b-col>
                  <b-col>{{ modalInfo.nama_plant }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Part :</b></b-col>
                  <b-col>{{ modalInfo.nama_part }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Sub Part :</b></b-col>
                  <b-col>{{ modalInfo.nama_sub_part }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Material :</b></b-col>
                  <b-col>{{ modalInfo.material }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Deskripsi :</b></b-col>
                  <b-col>{{ modalInfo.deskripsi }}</b-col>
                </b-row>
              </b-col>
              <b-col md="6">
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Jenis Data :</b></b-col>
                  <b-col>{{ modalInfo.nama_jenis_data }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Satuan :</b></b-col>
                  <b-col>{{ modalInfo.satuan }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Nilai Awal :</b></b-col>
                  <b-col>{{ modalInfo.nilai_awal }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Batas Max :</b></b-col>
                  <b-col>{{ modalInfo.batas_max }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Batas Min :</b></b-col>
                  <b-col>{{ modalInfo.batas_min }}</b-col>
                </b-row>

                <!-- <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Titik Ukur :</b></b-col>
                  <b-col>{{ modalInfo.titik_ukur }}</b-col>
                </b-row> -->

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Sketsa :</b></b-col>
                  <b-col>
                    <b-button v-if="modalInfo.sketsa" variant="outline-info" size="sm"
                      :href="urlStorage + '/file/condition_monitoring/' + modalInfo.sketsa" target="_blank">
                      <span class="svg-icon svg-icon-sm svg-icon-info">
                        <i class="flaticon2-folder text-info"></i>
                      </span>
                      Lihat File
                    </b-button>
                    <span v-else>Tidak ada file.</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col>
                <div id="container" style="width: 100%">
                  <axpex></axpex>
                  <chart :chartData="selectedItem"></chart>
                </div>
              </b-col>
            </b-row>
            <hr />
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-1 variant="info">Setting Titik Ukur</b-button>
                </b-card-header>
                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-form-group label-cols-sm="2" label-cols-lg="2" content-cols-sm content-cols-lg="6"
                      id="titik_ukur_label" label="Titik Ukur :" label-for="titik_ukur">
                      <b-form-radio-group v-model="titik_ukur" :options="['Text', 'MS. Excel']"
                        class="mb-3"></b-form-radio-group>
                    </b-form-group>
                    <b-row v-if="titik_ukur === 'Text'">
                      <b-col md="4" v-for="(row, index) in modalInfo.titik_ukur" :key="index">
                        <b-form-group label-cols-sm="3" label-cols-lg="3" content-cols-sm content-cols-lg="9"
                          :id="`data_titik_ukur_label_${index}`" :label="`${row.nama_titik_ukur} :`"
                          :label-for="`data_titik_ukur_${index}`">
                          <b-form-input size="sm" :id="`data_titik_ukur_${index}`" v-model="row.nilai_titik_ukur_new"
                            placeholder="Enter Text..." type="number"></b-form-input>
                          <!-- <b-row>
                            <b-col md="9">
                              <b-form-input
                                size="sm"
                                :id="`data_titik_ukur_${index}`"
                                v-model="row.nilai_titik_ukur"
                                placeholder="Enter Text..."
                              ></b-form-input>
                            </b-col>
                            <b-col md="3">
                              <b-button
                                variant="outline-info"
                                size="sm"
                                @click="saveTitikUkur(row)"
                              >
                                <i class="flaticon2-check-mark text-info text-sm"></i>
                              </b-button>
                            </b-col>
                          </b-row> -->
                        </b-form-group>
                      </b-col>
                      <b-col md="6" offset-md="3">
                        <b-button v-if="!loading" variant="success" block @click="saveAllTitikUkur">
                          <i class="flaticon2-check-mark text-light text-sm"></i> Simpan perubahan
                          nilai
                        </b-button>
                        <b-button v-else variant="success" block>
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row v-else-if="titik_ukur === 'MS. Excel'">
                      <b-col md="12">
                        <small>Harap download template titik ukur terlebih dahulu
                          <a @click="exportTemplateTitikUkur" href="/#/report/conditional_report">dsni</a></small>
                      </b-col>
                      <b-col md="6">
                        <b-form-file v-model="import_titik_ukur" placeholder="import excel titik ukur..."
                          drop-placeholder="import excel titik ukur..." accept=".xlsx"></b-form-file>
                      </b-col>
                      <b-col md="4">
                        <b-button variant="outline-success" size="sm" @click="importTitikUkur">
                          <i class="flaticon2-check-mark text-success text-sm"></i> Unggah Sekarang
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </b-modal>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import axios from 'axios'
import JwtService from '@/core/services/jwt.service'
import $ from 'jquery'
import * as moment from 'moment'
import 'moment/locale/id'
import chart from '../../../../components/condition-monitoring/chart.vue'
import axpex from '../../../pages/profile/profile-comp-2/Widget1.vue'

export default {
  data() {
    return {
      sortBy: 'nama_equipment',
      sortDesc: true,
      sort_label: 'DESC',
      items: [],
      selectedItem: [],
      fields: [
        { key: 'no', label: 'No', sortable: true },
        { key: 'nama_equipment', label: 'Equipment', sortable: true },
        { key: 'nama_part', label: 'Part', sortable: true },
        { key: 'nama_sub_part', label: 'Sub Part', sortable: true },
        { key: 'nama_pabrik', label: 'Pabrik', sortable: true },
        { key: 'nama_plant', label: 'Plant', sortable: true },
        {
          key: 'action',
          label: 'Action',
          sortable: true,
        },
      ],
      search: {
        nama_equipment: '',
        nama_part: '',
        nama_sub_part: '',
        pabrik_id: '',
        plant_id: '',
      },
      modalInfo: {},
      titik_ukur: 'Text',
      import_titik_ukur: null,

      pabrik: [],
      plant: [],

      totalRows: 0,
      totalPerPage: 0,
      currentPage: 1,

      isBusy: false,
      dialog: false,
      loading: false,

      urlStorage: ApiService.urlStorage(),

      series: [
        {
          data: [],
        },
        {
          data: [],
        },
      ],

    }
  },
  components: {
    KTCodePreview,
    chart, axpex
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Report', route: '' },
      { title: 'Condition Monitoring' },
    ])
  },
  created() {
    var self = this
    self.isBusy = true

    ApiService.get('/master/pabrik/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var pabrik = data.data
          for (let i = 0; i < pabrik.length; i++) {
            self.pabrik.push({
              value: pabrik[i].id_pabrik,
              text: pabrik[i].nama_pabrik,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })

    ApiService.get('/master/plant/all?sortBy=nama_plant&sortDesc=ASC')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var plant = data.data.data
          for (let i = 0; i < plant.length; i++) {
            self.plant.push({
              value: plant[i].id_plant,
              text: plant[i].nama_plant,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })

    ApiService.get(
      '/report/condition_monitoring?page=' +
      this.currentPage +
      '&sortBy=' +
      this.sortBy +
      '&sortDesc=' +
      this.sort_label
    )
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.items.splice(0, self.items.length)

          data.data.data.forEach(el => {
            el.titik_ukur.forEach(tu => {
              tu.nilai_titik_ukur_new = tu.nilai_titik_ukur
            })

            self.items.push(el)
          })

          self.totalRows = data.data.total
          self.totalPerPage = data.data.data.length
          self.isBusy = false
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    randomValues(count, min, max) {
      const delta = max - min;
      return Array.from({ length: count }).map(() => Math.random() * delta + min);
    },
    moment: function (date) {
      moment.locale('id')
      return moment(date).format('DD/MM/YYYY')
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.loadData()
    },
    paginate() {
      this.loadData()
    },
    sortTable() {
      this.sort_label = 'ASC'
      if (this.sortDesc) {
        this.sort_label = 'DESC'
      }
      this.sortDesc = !this.sortDesc
      this.loadData()
    },
    async loadData() {
      var self = this
      self.isBusy = true

      await this.timeout(100)
      ApiService.get(
        '/report/condition_monitoring?page=' +
        this.currentPage +
        '&nama_equipment=' +
        this.search.nama_equipment +
        '&nama_part=' +
        this.search.nama_part +
        '&nama_sub_part=' +
        this.search.nama_sub_part +
        '&pabrik_id=' +
        this.search.pabrik_id +
        '&plant_id=' +
        this.search.plant_id +
        '&sortBy=' +
        this.sortBy +
        '&sortDesc=' +
        this.sort_label
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.items.splice(0, self.items.length)

            data.data.data.forEach(el => {
              el.titik_ukur.forEach(tu => {
                tu.nilai_titik_ukur_new = tu.nilai_titik_ukur
              })

              self.items.push(el)
            })

            self.totalPerPage = data.data.data.length
            self.isBusy = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    info(item) {
      //this.loadData()
      this.series[0].data = []
      this.series[1].data = []
      console.log(item.id_condition_monitoring)
      item.titik_ukur.forEach(row => {
        this.selectedItem.push(row.nilai_titik_ukur);
      })
      //this.selectedItem = item;
      item.chart.forEach(row => {
        const minimum = row.data.minimum ? row.data.minimum : 0
        const q1 = row.data.firstquartile ? row.data.firstquartile : 0
        const median = row.data.median ? row.data.median : 0
        const q2 = row.data.secondquartile ? row.data.secondquartile : 0
        const maximum = row.data.maximum ? row.data.maximum : 0

        this.series[0].data.push({
          x: row.year,
          y: [minimum, q1, median, q2, maximum],
        })

        this.series[1].data.push({
          x: 2022,
          y: [minimum, q1, median, q2, maximum],
        })
      })
      this.modalInfo = item
      this.$bvModal.show('info-modal')
    },
    resetInfoModal() {
      this.modalInfo = {}
      this.titik_ukur = 'Text'
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
    exportTemplateTitikUkur() {
      const self = this
      axios({
        method: 'get',
        responseType: 'arraybuffer',
        url:
          '/report/condition_monitoring/export_template_titik_ukur/' +
          this.modalInfo.id_condition_monitoring,
        headers: {
          Accept: 'multipart/form-data',
          Authorization: 'Bearer ' + JwtService.getToken(),
        },
      }).then(function (response) {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute(
          'download',
          `template_titik_ukur_${self.modalInfo.id_condition_monitoring}.xlsx`
        )
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    saveTitikUkur(row) {
      const self = this
      ApiService.post(
        '/report/condition_monitoring/update_titik_ukur/' + row.id_condition_monitoring_titik_ukur,
        {
          nilai_titik_ukur: row.nilai_titik_ukur,
        }
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    saveAllTitikUkur() {
      let promises = []
      const self = this
      self.loading = true

      this.modalInfo.titik_ukur.forEach(row => {
        if (row.nilai_titik_ukur != Number(row.nilai_titik_ukur_new)) {
          promises.push(
            ApiService.post(
              '/report/condition_monitoring/update_titik_ukur/' +
              row.id_condition_monitoring_titik_ukur,
              {
                nilai_titik_ukur: row.nilai_titik_ukur_new,
              }
            )
          )
        }
      })

      if (!promises.length) {
        self.makeToast('success', 'Tidak ada data berubah')
        return
      }

      Promise.all(promises).then(() => {
        self.makeToast('success', 'Data berhasil disimpan')
        self.$bvModal.hide('info-modal')
        self.loading = false
        self.loadData()
      })
    },
    importTitikUkur() {
      if (!this.import_titik_ukur) {
        this.makeToast('danger', 'Tidak ada file excel terpilih')
        return
      }

      var self = this
      self.loading = true

      const import_titik_ukur = this.import_titik_ukur

      const dataku = new FormData()
      dataku.append('import_titik_ukur', import_titik_ukur)

      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/report/condition_monitoring/import_template_titik_ukur',
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function (response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.loadData()
            self.$bvModal.hide('info-modal')
          }
        },
      })
    },
    toDelete(row) {
      var id = row.item.id_condition_monitoring
      let self = this
      this.$confirm({
        auth: false,
        message: 'Anda yakin akan menghapus data tersebut?',
        button: {
          yes: 'Ya',
          no: 'Batal',
        },
        callback: confirm => {
          if (confirm) {
            self.isBusy = true
            ApiService.get('/report/condition_monitoring/delete/' + id).then(({ data }) => {
              if (data.status == 'ok') {
                var interval1 = null
                var i = 0
                self.loadData()
                interval1 = setInterval(function () {
                  if (i == 0) {
                    self.totalRows--
                    self.makeToast('warning', 'Data berhasil dihapus')
                    self.isBusy = false
                  } else {
                    clearInterval(interval1)
                  }
                  i++
                }, 1000)
              }
            })
          }
        },
      })
    },
  },
}
</script>
