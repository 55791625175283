<template>
    <div><canvas id="boxplot"></canvas></div>
</template>
<script setup>
import { Chart, registerables } from 'chart.js';
import { BoxPlotController, BoxAndWiskers } from '@sgratzl/chartjs-chart-boxplot';
import { onMounted, ref, watch } from 'vue';
Chart.register(...registerables, BoxPlotController, BoxAndWiskers);

const p = defineProps({
    chartData: []
})
const chartView = ref('')

const boxplotData = {
    labels: [],
    datasets: [{
        label: 'Conditional Monitoring',
        backgroundColor: 'rgba(255,0,0,0.5)',
        borderColor: 'red',
        borderWidth: 1,
        outlierColor: '#fff700',
        padding: 5,
        itemRadius: 5,
        data: [
        ]
    }]
};

onMounted(() => {
    const ctx = document.getElementById("boxplot").getContext("2d");
    // chartView.value = new Chart(ctx, {
    //     type: 'boxplot',
    //     data: boxplotData,
    //     options: {
    //         responsive: true,
    //         legend: {
    //             position: 'top',
    //         },
    //         title: {
    //             display: true,
    //             text: 'Chart.js Box Plot Chartx'
    //         }
    //     }
    // });

    chartView.value = new Chart(ctx, {
        type: 'boxplot',
        data: {
            datasets: [{
                label: 'Bar Dataset',
                data: [2, 3, 4, 5, 6]
            }, {
                type: 'line',
                label: 'Line Dataset',
                data: [3, 4, 5, 6],
            }],
            labels: ['January', 'February', 'March', 'April']
        },
    });

    // chartView.value.data.labels = []
    // chartView.value.data.datasets.forEach(dataset => {
    //     dataset.data = []
    // })

    // chartView.value.data.labels.push("2024")
    // chartView.value.data.datasets.forEach(dataset => {
    //     if (p.chartData.length > 0) {
    //         dataset.data.push(p.chartData)
    //     }

    // })
    // chartView.value.update()

})

</script>